export const EverChargeTab = () => {
  return (
    <>
      <div class="row">
        <div class="col-md-6 col-sm-12 col-12" style={{ marginTop: "50px" }}>
          <span>Ứng dụng di động</span>
          <p>EverCharge</p>
          <span>
            Ứng dụng thông minh dành cho chủ xe điện, chỉ cần vài cú chạm trên
            điện thoại, bạn đã có thể:
            <br />
          </span>
          <div>📍 Truy cập vào danh sách trạm sạc của EverCharge</div>
          <div>
            📍 Xác định vị trí hiện tại và hiển thị trạm sạc gần nhất với định
            vị GPS
          </div>
          <div>📍 Tìm điểm sạc trên đường đi với bộ lọc thông minh</div>
          <br />
          <span>
            Bạn cũng không cần lo lắng rằng chạy đến thì trạm sạc thì không còn
            chỗ trống. Trên ứng dụng này, EverCharge sẽ cung cấp thông tin chi
            tiết về số lượng ổ cắm trống tại mỗi trạm.
          </span>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <a
              href="https://play.google.com/store/apps/details?id=com.everpower.app"
              class="smoothScroll"
              target="_blank"
              style={{ marginRight: "20px" }}
            >
              <img src="images/google-play.png" class="img-responsive" alt="" />
            </a>
            <a
              href="https://apps.apple.com/vn/app/evercharge/id6459793707"
              class="smoothScroll"
              target="_blank"
            >
              <img src="images/appstore.png" class="img-responsive" alt="" />
            </a>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-12">
          <img
            src="images/evercharge-app.png"
            class="img-responsive"
            alt="EV-product1"
            height={536}
            width={536}
          />
        </div>
      </div>
    </>
  );
};

export const MOTab = () => {
  return (
    <>
      <div class="row">
        <div class="col-md-6 col-sm-12 col-12" style={{ marginTop: "50px" }}>
          <span>Hệ thống</span>
          <p>Mobility Operator</p>
          <span>
            Mobility Operator là giải pháp quản hệ thống sạc thông minh dành cho
            đối tác là các đơn vị cung cấp dịch vụ sạc pin tới khách hàng cá
            nhân. Với EverCharge mobility operator bạn có thể:
            <br />
          </span>
          <div>📍 Theo dõi doanh thu trên từng phiên sạc</div>
          <div>📍 Quản lý chi phí sạc</div>
          <div>📍 Quản lý khách hàng</div>
          <br />
          <span>
            Với giải pháp Mobility Operator, khách hàng của bạn sẽ dễ dàng sử
            dụng dịch vụ sạc của Evercharge. Hãy liên hệ ngay và tích hợp với
            chúng tôi.
          </span>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <a
              href="https://mo.evercharge.vn/"
              className="btn section-btn"
              target="_blank"
            >
              Truy cập ngay <i class="fa fa-arrow-right"></i>
            </a>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <img
            src="images/desktop-mo.png"
            class="img-responsive"
            alt="EV-product1"
            height={536}
            width={536}
          />
        </div>
      </div>
    </>
  );
};

export const CPOTab = () => {
  return (
    <>
      <div class="row">
        <div class="col-md-6 col-sm-12 col-12" style={{ marginTop: "50px" }}>
          <span>Hệ thống</span>
          <p>EverCharge CPO</p>
          <span>
            EverCharge CPO (Charge Point Operator) là hệ thống server điều khiển
            trạm sạc thông minh đáp ứng các tiêu chuẩn quốc tế về điều khiển
            trạm sạc. Với các tính năng:
            <br />
          </span>
          <div>
            📍 Điều khiển trạm sạc từ xa, đo đạc công suất điện áp trên từng
            phiên sạc
          </div>
          <div>📍 Quản lý thông số sạc</div>
          <div>📍 Thống kê điện năng tiêu thụ</div>
          <br />
          <span>
            Tích hợp trạm sạc của bạn với chúng tôi, bạn sẽ tiết kiệm tối đa
            nhân lực để vận hành trạm sạc của mình. Chỉ cần ngồi một chỗ, chúng
            tôi đem toàn bộ hệ thống trạm sạc đến bạn qua 1 website.
          </span>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <a
              href="https://cpo.evercharge.vn/"
              className="btn section-btn"
              target="_blank"
            >
              Truy cập ngay <i class="fa fa-arrow-right"></i>
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <img
            src="images/desktop-cpo.png"
            class="img-responsive"
            alt="EV-product1"
            height={536}
            width={536}
          />
        </div>
      </div>
    </>
  );
};
export const EverBusinessTab = () => {
  return (
    <>
      <div class="row">
        <div class="col-md-6 col-sm-12 col-12" style={{ marginTop: "50px" }}>
          <span>Ứng dụng di động</span>
          <p>EverBusiness</p>
          <span>
            Đây là ứng dụng thông minh được chúng tôi tặng miễn phí tới tất cả
            các chủ trạm sạc. Với các tính năng:
            <br />
          </span>
          <div>📍 Thống kê doanh số</div>
          <div>📍 Thống kê chi tiết phiên sạc trên các trụ sạc</div>
          <div>📍 Điều khiển trạm sạc từ xa</div>
          <br />
          <span>
            Với app evercharge business, bạn có toàn bộ hệ thống trạm sạc trong
            tay mình.
          </span>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <a
              href="https://play.google.com/store/apps/details?id=com.evc.business"
              class="smoothScroll"
              target="_blank"
              style={{ marginRight: "20px" }}
            >
              <img src="images/google-play.png" class="img-responsive" alt="" />
            </a>
            <a
              href="https://apps.apple.com/vn/app/evercharge-business/id6466397119"
              class="smoothScroll"
              target="_blank"
            >
              <img src="images/appstore.png" class="img-responsive" alt="" />
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <img
            src="images/business-app.png"
            class="img-responsive"
            alt="EV-product1"
            height={536}
            width={536}
          />
        </div>
      </div>
    </>
  );
};
