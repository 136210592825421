export const postMessageToApp = (msg) => {
    window.postMessage(msg, '*');
    if(window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(msg);
    }
}

export const ErrorMessage = [
    {
        code: '-52', 
        message: 'Yêu cầu không chính xác'
    },
    {
        code: '504', 
        message: 'Sai chữ ký'
    },
    {
        code: '-58', 
        message: 'Mã đơn hàng không hợp lệ hoặc đã tồn tại'
    },
    {
        code: '-27', 
        message: 'Token thẻ được dùng để thanh toán không hợp lệ'
    },
    {
        code: '-34', 
        message: 'Vượt quá hạn mức hoặc số lần thanh toán trong 1 ngày'
    },
    {
        code: '-35', 
        message: 'Vượt quá hạn mức hoặc số lần thanh toán trong 1 tháng'
    },
    {
        code: '514', 
        message: 'Hạn mức thanh toán không hợp lệ'
    },
    {
        code: '-50', 
        message: 'Lỗi thanh toán'
    },
    {
        code: '404', 
        message: 'Giao dịch đã hết hạn để thanh toán'
    },
    {
        code: '-16', 
        message: 'Sai thông tin nguồn tiền'
    },
    {
        code: '-56', 
        message: 'Không hỗ trợ phương thức thanh toán này'
    },
    {
        code: '-55', 
        message: 'Đối tác không hợp lệ hoặc'
    },
    {
        code: '-54', 
        message: 'Đối tác không hoạt động'
    },
    {
        code: '-53', 
        message: 'Đối tác không tồn tại'
    },
    {
        code: '599', 
        message: 'Có lỗi trong quá trình xử ly'
    },
    {
        code: '-59', 
        message: 'Chính sách phí không hợp lệ'
    },
    {
        code: '-60', 
        message: 'Giao dịch thanh toán không được phép thực hiện'
    },
    {
        code: '-65', 
        message: 'Lỗi gửi yêu cầu đồng thời'
    },
    {
        code: '-70', 
        message: 'Link thanh toán không tồn tại'
    },
    {
        code: '-71', 
        message: 'Link thanh toán đã được sử dụng'
    },
    {
        code: '-57', 
        message: 'Đầu bin của thẻ không được hỗ trợ'
    },
    {
        code: '402', 
        message: 'Giao dịch đã được xử lý'
    },
    {
        code: '600', 
        message: 'Ngân hang hỗ trợ trả góp không hợp lệ'
    },
    {
        code: '601', 
        message: 'Thương hiệu thẻ hỗ trợ trả góp không hợp lệ'
    },
    {
        code: '602', 
        message: 'Kỳ hạn trả góp không hợp lệ'
    },
    {
        code: '900', 
        message: 'Hệ thống bảo trì'
    },
    {
        code: '901', 
        message: 'Bảo trì nguồn tiền'
    },
    {
        code: '902', 
        message: 'Bảo trì nguồn tiền thẻ quốc tế'
    },
    {
        code: '903', 
        message: 'Bảo trì nguồn tiền thẻ nội địa'
    },
    {
        code: '-1', 
        message: 'Lỗi thất bại chung'
    },
    {
        code: '-3', 
        message: 'Bộ xử lý thanh toán thất bại'
    },
    {
        code: '-4', 
        message: 'Bộ xử lý thanh toán không phản hồi trong thời gian cho phép'
    },
    {
        code: '-5', 
        message: 'Ngân hàng không khả dụng Ngân hàng phát hành thẻ gặp lỗi bất thường'
    },
    {
        code: '-6', 
        message: 'Ngân hàng không phản hồi trong thời gian cho phép'
    },
    {
        code: '-7', 
        message: 'Xác thực người dung thất bại'
    },
    {
        code: '-9', 
        message: 'Ngân hàng xảy ra lỗi'
    },
    {
        code: '-11', 
        message: 'Thẻ hết hạn'
    },
    {
        code: '-12', 
        message: 'Thẻ bị từ chối thanh toán'
    },
    {
        code: '13', 
        message: 'Thẻ bị khóa'
    },
    {
        code: '-14', 
        message: 'Thẻ bị báo mất hoặc trộm'
    },
    {
        code: '-15', 
        message: 'Thẻ không đủ tiền'
    },
    {
        code: '-17', 
        message: 'Thẻ không hợp lệ'
    },
    {
        code: '-18', 
        message: 'Mã CVN/CVV không đúng'
    },
    {
        code: '-19', 
        message: 'Sai số thẻ'
    },
    {
        code: '-20', 
        message: 'Sai loại thẻ thanh toán'
    },
    {
        code: '-21', 
        message: 'Sai mã otp'
    },
    {
        code: '-22', 
        message: 'Mã otp hết hiệu lực'
    },
    {
        code: '-23', 
        message: 'Tài khoản thẻ không được phép thực hiện thanh toán'
    },
    {
        code: '-24', 
        message: 'Ngày phát hành thẻ sai'
    },
    {
        code: '-25', 
        message: 'Sai thông tin thẻ'
    },
    {
        code: '-26', 
        message: 'Sai thông tin chủ thẻ'
    },
    {
        code: '-28', 
        message: 'Thẻ không đăng ký thanh toán online'
    },
    {
        code: '-31', 
        message: 'Hạn mức thanh toán không hợp lệ'
    },
    {
        code: '-32', 
        message: 'Vượt quá hạn mức tín dụng'
    },
    {
        code: '-33', 
        message: 'Thẻ không đủ tiền'
    },
    {
        code: '-40', 
        message: 'Bộ xử lý thanh toán từ chối gdtt'
    },
    {
        code: '-41', 
        message: 'Phát hiện dấu hiệu gian lận'
    },
    {
        code: '-42', 
        message: 'Giao dịch bị từ chối chung'
    },
    {
        code: '-43', 
        message: 'Giao dịch bị đánh dấu do có dấu hiệu bất thường'
    },
    {
        code: '500', 
        message: 'Phát hiện dấu hiệu gian lận'
    },
    {
        code: '502', 
        message: 'Phát hiện dấu hiệu gian lận'
    },
]