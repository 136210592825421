import React, { useEffect, useState } from "react";
import "./style.css";
import { postMessageToApp } from "./helper";

const Failed = () => {
  const [timeLeft, setTimeLeft] = useState(10);
  useEffect(() => {
    const countdown = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      } else {
        clearInterval(countdown);
        postMessageToApp("web_request");
      }
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [timeLeft]);
  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <div id="payment-success">
        <div class="header">
          <img
            src="../../../logo/logo.svg"
            alt="header-logo"
            height={32}
            width={160}
            style={{padding: '10px'}}
          />
        </div>
        <div class="card2">
          <img src="../../../images/payment-failed.svg" alt="header-logo" />
          <div class="title">Thanh toán bị từ chối!</div>
          <p>Giao dịch đã bị hủy bởi người dùng</p>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <div class="counter-text">
              Hệ thống sẽ tự động chuyển trang sau <strong>{timeLeft}</strong>{" "}
              giây{" "}
            </div>
            <div class="back-home">
              <div class="back-home">
                <button
                  className="btn-no-border"
                  onClick={() => postMessageToApp("web_request")}
                >
                  Về Evercharge
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Failed;
