import React, { useState, useEffect } from "react";
import axios from "axios";
import { CPOTab, EverBusinessTab, EverChargeTab, MOTab } from "./product/tab";
import ChargingStationPage from "../stations";
import { message } from "antd";

const baseURL = process.env.REACT_APP_URL_API;
const token = process.env.REACT_APP_TOKEN;

const tabStyle = {
  backgroundColor: "transparent",
  color: "white",
  float: "left",
  border: "none",
  outline: "none",
  cursor: "pointer",
  padding: "14px",
  fontSize: "17px",
  width: "25%",
  borderRadius: "25px",
};

const activeTabStyle = {
  backgroundColor: "#2D9764",
};

const tabContentStyle = {
  color: "white",
  display: "none",
  padding: "100px 20px",
  height: "100%",
};

const activeTabContentStyle = {
  display: "flex",
};

const Home = () => {
  const [openTab, setOpenTab] = useState("EverCharge");

  const openPage = (pageName) => {
    setOpenTab(pageName);
  };

  const [customerInfo, setCustomerInfo] = useState({
    full_name: "",
    phone_number: "",
    email: "",
    content: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo({
      ...customerInfo,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(customerInfo);
    try {
      const response = await axios.post(`${baseURL}/supports`, customerInfo, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success(
        "Yêu cầu của bạn đã được chúng tôi ghi nhận, chúng tôi sẽ sớm liên lạc với bạn!"
      );
      setCustomerInfo({
        full_name: "",
        phone_number: "",
        email: "",
        content: "",
      });
    } catch (e) {
      alert("Có lỗi xảy ra!");
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    document.getElementById("defaultOpen")?.click();
  }, []);
  return (
    <>
      {/* <!-- MENU --> */}
      <section class="navbar custom-navbar navbar-fixed-top" role="navigation">
        <div class="container">
          <div class="navbar-header">
            <button
              class="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <span class="icon icon-bar"></span>
              <span class="icon icon-bar"></span>
              <span class="icon icon-bar"></span>
            </button>

            {/* <!-- lOGO TEXT HERE --> */}
            <a href="/" class="navbar-brand">
              <img
                src="logo/logo.svg"
                class="img-responsive"
                alt="EV-Charge Logo"
                height={40}
                width={209}
              />
            </a>
          </div>

          {/* <!-- MENU LINKS --> */}
          <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-nav-first">
              <li>
                <a href="#home" class="smoothScroll">
                  Trang chủ
                </a>
              </li>
              <li>
                <a href="#about" class="smoothScroll">
                  Về chúng tôi
                </a>
              </li>
              <li>
                <a href="#product" class="smoothScroll">
                  Sản phẩm
                </a>
              </li>
              <li>
                <a href="#station" class="smoothScroll">
                  Hệ thống trạm sạc
                </a>
              </li>
              <li>
                <a href="#contact" class="smoothScroll">
                  Liên hệ
                </a>
              </li>
              <li>
                <a
                  className="btn"
                  href="https://mo.evercharge.vn/"
                  target="_blank"
                >
                  Đăng nhập
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* <!-- HOME --> */}
      <section id="home" data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-7 col-sm-12">
              <div class="home-info">
                <h1>EverCharge Solution </h1>
                <span>
                  EverCharge là đơn vị tiên phong (ngoài Vinfast) tại Việt Nam
                  tự nghiên cứu, xây dựng hệ thống OCPP server điều khiển trạm
                  sạc.
                </span>
                <div style={{ marginBottom: "20px" }}>
                  <li>
                    Tương thích với 90% trạm sạc trên thế giới hiện tại theo
                    chuẩn OCPP 1.6J
                  </li>
                  <li>Đơn vị có hiểu biết thị trường Việt Nam</li>
                  <li>Linh hoạt, đáp ứng nhanh</li>
                  <li>Nhân sự chất lượng cao và đầu ngành</li>
                </div>
                <a href="#contact" className="btn section-btn">
                  Liên hệ ngay <i class="fa fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="col-md-5 col-sm-12">
              <img
                src="../images/home-bg-over.png"
                class="img-responsive"
                alt="EV-Charge Logo"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ABOUT --> */}
      <section id="about" data-stellar-background-ratio="0.6">
        <div class="container">
          <div class="row" style={{ marginBottom: "30px" }}>
            <h2>EverCharge Solution</h2>
            <p>
              Chúng tôi là 1 startup công nghệ năng động trong lĩnh vực xe điện.
              Với mục tiêu cung cấp đầy đủ mọi giải pháp phần mềm cho các hệ
              thống trạm sạc thông minh. Hệ thống phần mềm điều khiển trạm sạc
              của EverCharge hiện tương thích với tiêu chuẩn sạc OCPP 1.6 và
              hoàn thành tích hợp tới hơn 11 hãng trạm sạc như StarCharge, Beny,
              HiCi, Jointech...
            </p>
            <img
              src="images/solution.png"
              class="img-responsive"
              alt="EVC-solution"
            />
            <div class="col-md-6" style={{ marginTop: "30px" }}>
              <div class="col-md-1">
                <img
                  src="images/cpo-logo.png"
                  alt="cpo"
                  width={32}
                  height={36}
                />
              </div>
              <div class="col-md-11" style={{ marginBottom: "20px" }}>
                <span>EverCharge điều khiển trạm sạc của CSO</span>
              </div>
              <ul>
                <li>Mua điện của CSO</li>
                <li>Chia sẻ doanh thu với CSO</li>
              </ul>
            </div>
            <div class="col-md-6" style={{ marginTop: "30px" }}>
              <div class="col-md-1">
                <img src="images/mo-logo.png" alt="mo" width={32} height={36} />
              </div>
              <div class="col-md-11" style={{ marginBottom: "20px" }}>
                <span>EverCharge thu phí sạc của chủ xe ô tô điện</span>
              </div>
              <ul>
                <li>Tính phí bao gồm VAT</li>
                <li>Tính phí của KH hộ CSO</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- product --> */}
      <section id="product" data-stellar-background-ratio="0.5">
        {/* <div class="overlay"></div> */}
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <p>Sản phẩm</p>
            </div>
            <div class="col-md-9 col-sm-12">
              {[
                "EverCharge",
                "Mobility Operator",
                "ChargePoint Operator",
                "EverBusiness",
              ].map((tabName) => (
                <button
                  key={tabName}
                  style={{
                    ...tabStyle,
                    ...(openTab === tabName ? activeTabStyle : {}),
                  }}
                  onClick={() => openPage(tabName)}
                >
                  {tabName}
                </button>
              ))}
            </div>
          </div>
          {[
            "EverCharge",
            "Mobility Operator",
            "ChargePoint Operator",
            "EverBusiness",
          ].map((tabName) => (
            <div
              key={tabName}
              id={tabName}
              style={{
                ...tabContentStyle,
                ...(openTab === tabName ? activeTabContentStyle : {}),
              }}
            >
              {tabName === "EverCharge" ? (
                <EverChargeTab />
              ) : tabName === "Mobility Operator" ? (
                <MOTab />
              ) : tabName === "ChargePoint Operator" ? (
                <CPOTab />
              ) : (
                <EverBusinessTab />
              )}
            </div>
          ))}
        </div>
      </section>
      <section id="station" data-stellar-background-ratio="0.5">
        <div class="container">
          <ChargingStationPage />
        </div>
      </section>
      {/* <!-- CONTACT --> */}
      <section id="contact" data-stellar-background-ratio="0.5">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="section-title">
                <h2>Liên hệ với chúng tôi</h2>
                <p>
                  Nếu bạn có bất kỳ yêu cầu nào. Đừng ngần ngại liên hệ với
                  chúng tôi.
                </p>
              </div>
            </div>
            <div class="col-md-7 col-sm-7">
              <img
                src="images/contact.png"
                class="img-responsive"
                alt="contact-us"
              />
            </div>
            <div class="col-md-5 col-sm-5">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Họ và tên"
                  id="full_name"
                  name="full_name"
                  value={customerInfo.full_name}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="email"
                  class="form-control"
                  placeholder="Địa chỉ email"
                  id="email"
                  name="email"
                  value={customerInfo.email}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="tel"
                  class="form-control"
                  placeholder="Số điện thoại"
                  id="phone_number"
                  name="phone_number"
                  value={customerInfo.phone_number}
                  onChange={handleInputChange}
                  pattern="0\d{9}|0\d{10}"
                  required
                />
                <textarea
                  class="form-control"
                  rows="6"
                  placeholder="Tin nhắn"
                  id="content"
                  name="content"
                  value={customerInfo.content}
                  onChange={handleInputChange}
                ></textarea>
                <div class="col-md-4 col-sm-12">
                  <input
                    type="submit"
                    class="form-control"
                    name="submit"
                    value="Gửi yêu cầu"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- FOOTER --> */}
      <footer data-stellar-background-ratio="0.5">
        <div class="container">
          <div class="row">
            <div class="col-md-5 col-sm-12">
              <div class="footer-thumb footer-info">
                <img
                  src="logo/logo.svg"
                  alt="footer-logo"
                  height={40}
                  width={209}
                />
                <h3>CÔNG TY CP NĂNG LƯỢNG EVERCHARGE</h3>
                <p>Xanh - Nhanh - Thông Minh</p>
                <div style={{ display: "flex" }}>
                  <ul class="social-icon">
                    <li>
                      <a
                        href="https://www.facebook.com/evercharge.sacxedienthongminh"
                        class="fa fa-facebook-square"
                        attr="facebook icon"
                        target="_blank"
                      ></a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@EverEv"
                        class="fa fa-youtube"
                        target="_blank"
                      ></a>
                    </li>
                    <li>
                      <a href="https://zalo.me/g/ixbagw399" target="_blank">
                        <img
                          src="logo/Zalo.svg"
                          alt="zalo-logo"
                          height={26}
                          width={26}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="footer-thumb">
                <h2 className="footer-heading">Truy cập nhanh</h2>
                <ul class="footer-link">
                  <li>
                    <a href="#about" class="smoothScroll">
                      Về chúng tôi
                    </a>
                  </li>
                  <li>
                    <a href="#product" class="smoothScroll">
                      Sản phẩm nổi bật
                    </a>
                  </li>
                  <li>
                    <a href="#contact" class="smoothScroll">
                      Liên hệ
                    </a>
                  </li>
                  <li>
                    <a href="https://mo.evercharge.vn/" target="_blank">
                      Đăng nhập
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3 col-sm-4">
              <div class="footer-thumb">
                <h2>Thông tin liên lạc</h2>
                <ul class="social-icon-address">
                  <i class="fa fa-map-marker" aria-hidden="true">
                    <span style={{ marginLeft: "10px" }}>
                      300B Minh Khai, Phường Minh Khai, Quận Hai Bà Trưng, Hà
                      Nội
                    </span>
                  </i>
                  <i class="fa fa-phone" aria-hidden="true">
                    <span style={{ marginLeft: "10px" }}>
                      <a href="tel:0946228800">094.622.8800</a>
                    </span>
                  </i>
                  <i class="fa fa-envelope" aria-hidden="true">
                    <span style={{ marginLeft: "10px" }}>
                      evercharge.vn@gmail.com
                    </span>
                  </i>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="footer-bottomX">
        <p>
          EverCharge. All rights reserved. © Copyright{" "}
          {`${new Date().getFullYear()}`}{" "}
        </p>
      </div>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "Trang chủ",
              item: "https://evercharge.vn/",
            },
            // Add more ListItem objects for other pages in the breadcrumb trail
          ],
        })}
      </script>

      {/* Schema.org markup for LocalBusiness (your company information) */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          name: "CÔNG TY CP NĂNG LƯỢNG EVERCHARGE",
          description: "Xanh - Nhanh - Thông Minh",
          address: {
            "@type": "PostalAddress",
            streetAddress: "300B Minh Khai, Phường Minh Khai",
            addressLocality: "Quận Hai Bà Trưng",
            addressRegion: "Hà Nội",
            postalCode: "Your postal code",
            addressCountry: "Vietnam",
          },
          telephone: "032.693.1688",
          email: "evercharge.vn@gmail.com",
          url: "https://evercharge.vn/",
          sameAs: [
            "https://www.facebook.com/evercharge.sacxedienthongminh",
            // Add more social media profiles if applicable
          ],
        })}
      </script>
    </>
  );
};

export default Home;
