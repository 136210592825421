import React from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  SVGOverlay,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import EVCMarker from "./icon/evc-marker.svg"; // Ensure this path is correct
import { Button, Typography } from "antd";
import { ReactComponent as LocationSvg } from "./icon/Location.svg";
import { ReactComponent as StarSvg } from "./icon/star.svg";
import { ReactComponent as ChargingStationSvg } from "./icon/charging_station.svg";

const customIcon = new L.Icon({
  iconUrl: EVCMarker,
  iconSize: [48, 48],
  iconAnchor: [15, 45],
  popupAnchor: [0, -45],
});

const boundHoangSa = [
  [16.90293702652209, 100.16453337003615],
  [15.913539832334903, 123.59803480229532],
];
const boundTruongSa = [
  [11.734346361568868, 110.83372991513322],
  [9.734346361568868, 120.83372991513322],
];

const OpenLayersMap = ({ markers }) => {
  const handleDirectionsClick = (lat, lng) => {
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    window.open(googleMapsUrl, "_blank");
  };

  return (
    <MapContainer
      center={[17.016770, 106.982464]}
      zoom={6}
      style={{ width: "100%", height: "60vh", borderRadius: "8px" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <SVGOverlay attributes={{ stroke: "red" }} bounds={boundHoangSa}>
        <text
          x="50%"
          y="50%"
          stroke="white"
          style={{ fontSize: "15px", fontWeight: "600" }}
        >
          {"Hoàng Sa(Việt Nam)"}
        </text>
      </SVGOverlay>
      <SVGOverlay attributes={{ stroke: "red" }} bounds={boundTruongSa}>
        <text
          x="50%"
          y="50%"
          stroke="white"
          style={{ fontSize: "15px", fontWeight: "600" }}
        >
          {"Trường Sa(Việt Nam)"}
        </text>
      </SVGOverlay>
      {markers.map((marker, index) => (
        <Marker
          position={[marker.lat, marker.lng]}
          key={marker.id}
          icon={customIcon}
        >
          <Popup>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography.Text style={{ fontSize: "16px", fontWeight: "500" }}>
                {marker?.name}
              </Typography.Text>

              <div style={{ display: "flex", alignItems: "center" }}>
                <LocationSvg
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "4px",
                  }}
                />
                <Typography.Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#52525B",
                    verticalAlign: "middle",
                  }}
                >
                  {`${marker?.address.slice(0, 30)}...`}
                </Typography.Text>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <StarSvg
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "4px",
                  }}
                />
                <Typography.Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#52525B",
                    verticalAlign: "middle",
                  }}
                >
                  5.0
                </Typography.Text>

                <Typography.Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "#52525B",
                    margin: "0 8px",
                    verticalAlign: "middle",
                  }}
                >
                  {"|"}
                </Typography.Text>

                <ChargingStationSvg
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "4px",
                  }}
                />
                <Typography.Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "#52525B",
                    verticalAlign: "middle",
                  }}
                >
                  {marker?.total_connector}/{marker?.total_connector} Cổng sạc
                  khả dụng
                </Typography.Text>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                <Button
                  style={{
                    width: "100%",
                    height: "36px",
                    color: "#fff",
                    backgroundColor: "#2D9764",
                  }}
                  onClick={() => handleDirectionsClick(marker.lat, marker.lng)}
                >
                  Chỉ đường
                </Button>
              </div>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default OpenLayersMap;
