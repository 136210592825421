import React, { useEffect, useState } from "react";
import "./style.css";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { ErrorMessage, postMessageToApp } from "./helper";

export function isNullOrEmpty(value) {
  return value === undefined || value === null || value === "";
}
export function formatCurrency(money, digits) {
  if (isNullOrEmpty(money)) {
    return "";
  }
  let format = "$1,";
  return `${digits ? money : Math.trunc(+money)}`.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    format
  );
}

const Success = () => {
  const [timeLeft, setTimeLeft] = useState(20);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const transaction_id = searchParams.get("transaction_id");
  const message = searchParams.get("message");
  const result = searchParams.get("result");
  const amount = searchParams.get("amount");
  const currency = searchParams.get("currency");
  const result_code = searchParams.get("result_code");
  const [date_time_now] = useState(moment(new Date()).format("DD-MM-YYYY HH:mm:ss"));
  useEffect(() => {
    const countdown = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      } else {
        clearInterval(countdown);
        postMessageToApp("web_request");
      }
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [timeLeft]);
  return (
    <>
      <div id="payment-success">
        <div class="header">
          <img
            src="../../../logo/logo.svg"
            alt="header-logo"
            height={32}
            width={160}
            style={{padding: '10px'}}
          />
        </div>
        <div class="card">
          {result_code === "200" ? (
            <>
              <img
                src="../../../images/payment-success.svg"
                alt="header-logo"
              />
              <div class="title">Thanh toán thành công!</div>
              <div class="money">
                {amount ? `${formatCurrency(amount)} ${currency}` : ""}
              </div>
            </>
          ) : (
            <>
              <img src="../../../images/payment-failed.svg" alt="header-logo" />
              <div class="title">Thanh toán không thành công!</div>
              <div class="money">
                {amount ? `${formatCurrency(amount)} ${currency}` : ""}
              </div>
              <div class="text-failed">{result_code} : {ErrorMessage.find(item => item.code === result_code)?.message ?? 'Có lỗi xảy ra'}</div>
            </>
          )}
          <div
            class="title-detail"
            style={{ textAlign: "left", marginTop: "32px" }}
          >
            Thông tin giao dịch
          </div>
          <table>
            <tr>
              <td style={{ textAlignLast: "left" }}>Mã đơn hàng</td>
              <td style={{ textAlignLast: "right" }}>{transaction_id ?? ""}</td>
            </tr>
            <tr>
              <td style={{ textAlignLast: "left" }}>Nội dung</td>
              <td style={{ textAlignLast: "right" }}>{message ?? ""}</td>
            </tr>
            <tr>
              <td style={{ textAlignLast: "left" }}>Thời gian thanh toán</td>
              <td style={{ textAlignLast: "right" }}>{date_time_now}</td>
            </tr>
            <tr>
              <td style={{ textAlignLast: "left" }}>Nguồn tiền</td>
              <td style={{ textAlignLast: "right" }}>
                -
              </td>
            </tr>
            <tr>
              <td style={{ textAlignLast: "left" }}>Số tiền giao dịch</td>
              <td style={{ textAlignLast: "right" }}>
                {amount ? `${formatCurrency(amount)} ${currency}` : ""}
              </td>
            </tr>
            <tr>
              <td style={{ textAlignLast: "left" }}>Phí giao dịch</td>
              <td style={{ textAlignLast: "right" }}>
               0 {currency}
              </td>
            </tr>
          </table>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <div class="counter-text">
              Hệ thống sẽ tự động chuyển trang sau <strong>{timeLeft}</strong>{" "}
              giây{" "}
            </div>
            <div class="back-home">
              <button
                className="btn-no-border"
                onClick={() => postMessageToApp("web_request")}
              >
                Về Evercharge
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Success;
