import './App.css';
import Home from './views/home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Success from './views/return-url/success';
import Failed from './views/return-url/failed';
import Pending from './views/return-url/pending';
import NotFound from './views/not-found';

function App() {
  return (
    <div className="App">
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/payment/fpt/confirmation" element={<Success />} />
        <Route path="/payment/fpt/cancelation" element={<Failed />} />
        <Route path="/payment/fpt/failed" element={<Failed />} />
        <Route path="/payment/fpt/pending" element={<Pending />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
