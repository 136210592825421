import React, { useEffect, useState } from "react";
import { Row, Col, Card, Typography, Input, Empty, Select } from "antd";
import OpenLayersMap from "./components/Maps";
import axios from "axios";
import { ReactComponent as LocationSvg } from "./components/icon/Location.svg";
import { ReactComponent as StarSvg } from "./components/icon/star.svg";
import { ReactComponent as ChargingStationSvg } from "./components/icon/charging_station.svg";
import { debounce } from "lodash";

const { Text } = Typography;

const { Search } = Input;
const { Option } = Select;
const baseURL = process.env.REACT_APP_URL_API;
const token =
  "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNzAxNzQ1Mjc4LCJleHAiOjE3ODgxNDUyNzh9.1qKh0owb0qgN_LIVx_Un8-ZXRxl9qsBgFPqDRyZpC2E";

const ChargingStationPage = () => {
  const [stations, setStations] = useState([]);
  const [stationName, setStationName] = useState("");
  const [listProvince, setListProvince] = useState([]);
  const [province, setProvince] = useState(null);
  const [listDistrict, setListDistrict] = useState([]);
  const [district, setDistrict] = useState(null);

  const onChangeProvince = (value) => {
    setDistrict(null);
    setListDistrict([]);
    setProvince(value);
    if (value) {
      fetchDistrict(value);
    }
  };

  const onChangeDistrict = (value) => {
    setDistrict(value);
  };

  const fetchProvince = async () => {
    try {
      const response = await axios.get(
        `https://api.evercharge.vn/areas/get-by-parent`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setListProvince(response?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };
  const fetchDistrict = async (code) => {
    try {
      const response = await axios.get(
        `https://api.evercharge.vn/areas/get-by-parent`,
        {
          params: {
            parent_code: code,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setListDistrict(response?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDepots = async () => {
    try {
      const response = await axios.get(
        `https://api.evercharge.vn/admin/depots/search?page=0&size=9999`,
        {
          params: {
            depot_name: stationName ?? undefined,
            province_code: province ?? undefined,
            district_code: district ?? undefined,
          },
        }
      );
      const convertData = response?.data?.data?.map((dp) => ({
        id: dp?.id || "",
        name: dp?.depot_name || "",
        code: dp?.depot_code || "",
        address: dp?.address || "",
        lat: dp?.latitude || "",
        lng: dp?.longitude || "",
        partner_id: dp?.partner_id || "",
        show: false,
        depot: true,
        total_connector: dp?.total_connector,
      }));
      setStations(convertData);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchDepots();
  }, [stationName, province, district]);

  useEffect(() => {
    fetchProvince();
  }, []);

  const debounceInputSearch = debounce((value) => setStationName(value), 500);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "16px",
        }}
      >
        <Typography.Text
          style={{ fontSize: "44px", fontWeight: "600", color: "#2D9764" }}
        >
          Hệ thống trạm sạc
        </Typography.Text>
      </div>
      <Row gutter={[8, 8]} style={{ marginBottom: "16px" }}>
        <Col md={12} sm={24}>
          <Search
            placeholder="Tìm kiếm tên trạm sạc"
            onChange={(e) => debounceInputSearch(e.target.value)}
            style={{ height: "36px", width: "100%" }}
          />
        </Col>
        <Col md={6} sm={12}>
          <Select
            showSearch
            value={province}
            onChange={onChangeProvince}
            placeholder="Tỉnh/Thành phố"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: "100%" }}
          >
            {listProvince?.length && (
              <>
                <Option key={undefined} value={undefined}>
                  Tất cả
                </Option>
                {listProvince.map((province) => (
                  <Option key={province.code} value={province.code}>
                    {province.name}
                  </Option>
                ))}
              </>
            )}
          </Select>
        </Col>
        <Col md={6} sm={12}>
          <Select
            showSearch
            value={district}
            onChange={onChangeDistrict}
            placeholder="Quận/Huyện"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: "100%" }}
          >
            {listDistrict?.length && (
              <>
                <Option key={undefined} value={undefined}>
                  Tất cả
                </Option>
                {listDistrict.map((district) => (
                  <Option key={district.code} value={district.code}>
                    {district.name}
                  </Option>
                ))}
              </>
            )}
          </Select>
        </Col>
      </Row>
      {stations?.length ? (
        <Row gutter={16} style={{ height: "60vh" }}>
          <Col span={8} style={{ overflowY: "auto", height: "100%" }}>
            <div style={{ marginBottom: "8px" }}>
              <Typography.Text
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                {stations?.length} trạm sạc
              </Typography.Text>
            </div>

            {stations.map((station) => (
              <Card key={station.id} style={{ marginBottom: "16px" }}>
                <Text style={{ fontSize: "16px", fontWeight: "500" }}>
                  {station.name}
                </Text>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "8px",
                  }}
                >
                  <LocationSvg
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginRight: "4px",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#52525B",
                      verticalAlign: "middle",
                    }}
                  >
                    {`${station.address}`}
                  </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "8px",
                  }}
                >
                  <StarSvg
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginRight: "4px",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#52525B",
                      verticalAlign: "middle",
                    }}
                  >
                    5.0
                  </Text>

                  <Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      color: "#52525B",
                      margin: "0 8px",
                      verticalAlign: "middle",
                    }}
                  >
                    {"|"}
                  </Text>

                  <ChargingStationSvg
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginRight: "4px",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      color: "#52525B",
                      verticalAlign: "middle",
                    }}
                  >
                    {station?.total_connector}/{station?.total_connector} Cổng
                    sạc khả dụng
                  </Text>
                </div>
              </Card>
            ))}
          </Col>
          <Col span={16}>
            {/* Map */}
            <OpenLayersMap markers={stations} />
          </Col>
        </Row>
      ) : (
        <Empty description="Không có dữ liệu!" />
      )}
    </div>
  );
};

export default ChargingStationPage;
